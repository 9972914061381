<template>
    <div>
        <canvas id="canvas" width="250" height="250" v-show="false"></canvas>
        <div id="pdfDom" class="lawyer">
            <div class="title">律师函</div>
            <div class="row">致：{{ data.userName }}（身份证号：{{ data.idCard }}）</div>
            <div class="row">&nbsp;&nbsp;&nbsp;&nbsp;受{{ data.businessName
            }}（以下简称“委托人”）的委托，贵州听盈律师事务所指派杨涛律师，就你方未按期归还服务费相关事宜，特函告如下：</div>
            <div class="block">&nbsp;&nbsp;&nbsp;&nbsp;一、根据委托人提供的证据材料，本所确认如下事实：</div>
            <div class="row">&nbsp;&nbsp;&nbsp;&nbsp;{{ data.applicationTime }}你与培训机构签署《分期付款协议》，约定以分期方式支付服务费{{
                data.installmentAmount }}元，期限{{ data.numberOfInstallments }}个月。后该机构与委托人签署《应收账款转让协议》，
                约定将你名下该笔剩余应收服务费转让至委托人。就此事项，你以签署《应收账款转让通知回执》的方式进行了确认，委托人已依法取得了对你的该笔债权。</div>
            <div class="row">
                &nbsp;&nbsp;&nbsp;&nbsp;截止{{ data.deadline }}，你累计欠付费用已达{{ data.totalAmountOwed }}元。
            </div>
            <div class="block">
                &nbsp;&nbsp;&nbsp;&nbsp;二、基于上述事实，我们认为：
            </div>
            <div class="row">
                &nbsp;&nbsp;&nbsp;&nbsp;你逾期清偿费用的行为已构成重大违约，严重损害了委托人合法权益。你应当按照《分期付款协议》及《中华人民共和国民法典》等双方约定和法律法规的规定及时、足额履行还款义务。
            </div>
            <div class="block">
                &nbsp;&nbsp;&nbsp;&nbsp;三、根据上述分析，特向你致函正告：
            </div>
            <div class="row">
                &nbsp;&nbsp;&nbsp;&nbsp;1、建议你方在收到本律师函后<span class="red">七个工作日</span>内联系委托人并支付欠付费用。
            </div>
            <div class="row">
                &nbsp;&nbsp;&nbsp;&nbsp;2、若你仍逾期拒不支付上述款项，受托律师将代表委托人依法向人民法院提起诉讼，追究你的全部法律责任，届时你所承担的费用必将大于上述金额，且一切不良后果将由你自行承担。务请重视，以免诉累。
            </div>
            <div class="block">
                &nbsp;&nbsp;&nbsp;&nbsp;特此函告。
            </div>
            <div class="red">
                &nbsp;&nbsp;&nbsp;&nbsp;可能涉及的诉讼风险提示：
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="under">1、司法送达后拒不到庭，法院可依法缺席判决；</span>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="under">2、承担案诉讼费、保全费、公告费等相关诉讼费用；</span>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="under">3、拒不履行生效裁判文书，被纳入法院失信被执行人名单，限制高消费；</span>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="under">4、一经立案你将承担更多的诉讼费用、耗费更多的时间成本等。</span>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="under">收件人如有异议可凭本函与委托人联系，联系人 {{ data.followName
                }}，电话：{{ data.followMobile }}</span>
            </div>
            <div style="display: flex;flex-direction: column;align-items: flex-end;">
                <el-image v-if="imageUrl" :src="imageUrl" style="width: 150px;height: 150px;margin-bottom: -170px;z-index: 1;"></el-image>
                <div class="row" style="align-self: flex-end;margin-top: 20px;z-index: 10;">
                    {{ data.lawFirm }}
                </div>
                <div class="row"
                    style="align-self: flex-end;margin-top: 20px;flex-wrap: nowrap;display: flex;flex-direction: row; z-index: 10;">
                    <span class="block">主办律师：</span><span>{{ data.leadLawyer }}</span>
                </div>
                <div class="block" style="align-self: flex-end;margin-top: 20px;z-index: 10;">
                    {{ data.effectiveTime }}
                </div>
            </div>
        </div>
        <el-button type="text" size="small" @click="exports" style="align-self: center;width: 100%;">导出</el-button>
    </div>
</template>
<script>
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { getLawyer } from "@/api/util.js";
import { createSeal } from '../../utils/canvas_help.js'
export default {
    mounted() {
        if (this.$route.query.billID) {
            this.getInfo(this.$route.query.billID)
        }
    },
    data() {
        return {
            data: {},
            showLawyer: false,
            imageUrl: ''
        }
    },
    methods: {
        exports() {
            html2Canvas(document.querySelector('#pdfDom'), {
                allowTaint: true
            }).then(function (canvas) {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 860
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save('律师函.pdf')
            })
        },
        async getInfo(id) {
            const params = {
                billID: id
            }
            const { code, data } = await getLawyer(params)
            if (code == 200) {
                this.data = data
                createSeal('canvas', data.lawFirm, '')
                this.imageUrl = document.getElementById("canvas").toDataURL("image/png")
                this.showLawyer = true
            } else {
                this.showLawyer = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.lawyer {
    width: 100%;
    height: 100%;
    padding: 8% 25%;
    display: flex;
    flex-direction: column;
}

.title {
    align-self: center;
    font-size: 35px;
    margin-bottom: 20px;
    font-family: KaiTi;
    font-weight: 600;
}

.row {
    font-size: 23px;
    line-height: 1.5;
    font-family: KaiTi;
}

.block {
    font-weight: 600;
    font-size: 23px;
    line-height: 1.5;
    font-weight: 600;
    font-family: KaiTi;
}

.red {
    color: red;
    font-weight: 600;
    font-size: 23px;
    line-height: 1.5;
    font-weight: 600;
    font-family: KaiTi;
}

.under {
    font-size: 23px;
    line-height: 1.5;
    font-family: STFangsong;
    text-decoration: underline;
}
</style>